import { MAX_NUMBER, RENTAL_APPLICATION_NOT_FOUND, RentalHistoryField } from '~/assets/strings';
import { NoEmploymentHistoryError } from '~/errors/validationError';
import { RentalApplicationErrorCode, EmploymentInformation, EmploymentHistoryErrorCode } from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class ValidateRentalApplicationInfoUseCase extends MagicUseCase {
  private nameRegex = /^[a-zA-Z\s]{2,150}$/;
  private ssnRegex = /^\d{3}-{0,1}\d{2}-{0,1}\d{4}$/;
  private dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  private emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  protected async runLogic(fieldName: string) {
    const application = this.getState().user.rentalApplication.application;
    if (!application) {
      throw new Error(RENTAL_APPLICATION_NOT_FOUND);
    }

    if (fieldName) {
      const fieldPathArr = fieldName.toString().split('.');
      if (fieldPathArr.length === 3) {
        this.validateSingleField(fieldName);
        return;
      }

      switch (fieldName) {
        case 'firstName':
          this.validateFirstName();
          break;
        case 'lastName':
          this.validateLastName();
          break;
        case 'email':
          this.validateEmail();
          break;
        case 'phone':
          this.validatePhone();
          break;
        case 'ssn':
          this.validateSSN();
          break;
        case 'dateOfBirth':
          this.validateDateOfBirth();
          break;
        case 'annualIncome':
          this.validateAnnualIncome();
          break;
        default:
          console.warn('No validation rule found for', fieldName);
          break;
      }
    } else {
      this.getState().user.rentalApplication.errors = [];
      this.getState().user.rentalApplication.employmentHistoryErrors = [];
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      this.validatePhone();
      this.validateSSN();
      this.validateDateOfBirth();
      this.validateAnnualIncome();
      this.validateEmploymentHistory();
      this.validateHasAccesptedMagicDoorEULA();
      this.validateHasAccesptedTransUnionEULA();
    }
  }

  private validateSingleField = (fieldPath: string) => {
    const employmentHistory = this.getState().user.rentalApplication.application?.employmentHistory;
    if (!employmentHistory || employmentHistory.length === 0) {
      throw new NoEmploymentHistoryError();
    }

    const fieldPathArr = fieldPath.split('.');
    const fieldName = fieldPathArr[2];

    if (fieldPathArr[0] !== 'employment') {
      throw new NoEmploymentHistoryError();
    }

    const employmentElement = employmentHistory[Number(fieldPathArr[1])];
    const errors: EmploymentHistoryErrorCode[] = [
      ...(this.getState().user.rentalApplication.employmentHistoryErrors[Number(fieldPathArr[1])] || []),
    ];

    switch (fieldName) {
      case 'name':
        this.addErrorToArray(errors, this.validateEmployerName(employmentElement.name), fieldName);
        break;
      case 'phone':
        this.addErrorToArray(errors, this.validateEmployerPhoneNumber(employmentElement.phone), fieldName);
        break;
      case 'position':
        this.addErrorToArray(errors, this.validatePosition(employmentElement.position), fieldName);
        break;
      case 'salary':
        this.addErrorToArray(errors, this.validateSalary(employmentElement?.salary), fieldName);
        break;
      case 'startDate':
        this.addErrorToArray(errors, this.validateStartDate(employmentElement.startDate), fieldName);
        break;
      case 'endDate':
        this.addErrorToArray(errors, this.validateEndDate(employmentElement.endDate, employmentElement.startDate), fieldName);
        break;
      default:
        console.warn('No validation rule found for field:', fieldName);
        break;
    }

    const index = Number(fieldPathArr[1]);
    if (this.getState().user.rentalApplication.employmentHistoryErrors.length < index) {
      for (let i = 0; i < index; i++) {
        this.getState().user.rentalApplication.employmentHistoryErrors.push([]);
      }
    }
    this.getState().user.rentalApplication.employmentHistoryErrors[index] = errors;
  };

  private addErrorToArray = (errors: EmploymentHistoryErrorCode[], error?: EmploymentHistoryErrorCode, label?: string) => {
    if (label) {
      const errorsToRemove = [];
      switch (label) {
        case RentalHistoryField.Name:
          errorsToRemove.push(EmploymentHistoryErrorCode.InvalidNameError);
          break;
        case RentalHistoryField.Phone:
          errorsToRemove.push(EmploymentHistoryErrorCode.InvalidPhoneError);
          break;
        case RentalHistoryField.Position:
          errorsToRemove.push(EmploymentHistoryErrorCode.InvalidPositionError);
          break;
        case RentalHistoryField.Salary:
          errorsToRemove.push(EmploymentHistoryErrorCode.InvalidSalaryError);
          break;
        case RentalHistoryField.StartDate:
          errorsToRemove.push(EmploymentHistoryErrorCode.InvalidStartDateError);
          break;
        case RentalHistoryField.EndDate:
          errorsToRemove.push(EmploymentHistoryErrorCode.InvalidEndDateError, EmploymentHistoryErrorCode.InvalidEndDateEarlierError);
          break;
        default:
          console.warn('No validation rule found for label:', label);
          break;
      }

      for (const relatedError of errorsToRemove) {
        const index = errors.indexOf(relatedError);
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }
    }

    if (typeof error === 'number') {
      errors.push(error);
    }
  };

  private validateFirstName = () => {
    const errors = this.getState().user.rentalApplication.errors;
    this.getState().user.rentalApplication.errors = errors.filter((error) => error !== RentalApplicationErrorCode.InvalidFirstName);

    const firstName = this.getState().user.rentalApplication.application?.firstName;
    if (!firstName || !this.nameRegex.test(firstName)) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidFirstName);
    }
  };

  private validateLastName = () => {
    const errors = this.getState().user.rentalApplication.errors;
    this.getState().user.rentalApplication.errors = errors.filter((error) => error !== RentalApplicationErrorCode.InvalidLastName);

    const lastName = this.getState().user.rentalApplication.application?.lastName;
    if (!lastName || !this.nameRegex.test(lastName)) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidLastName);
    }
  };

  private validateEmail = () => {
    const errors = this.getState().user.rentalApplication.errors;
    this.getState().user.rentalApplication.errors = errors.filter((error) => error !== RentalApplicationErrorCode.InvalidEmail);

    const email = this.getState().user.rentalApplication.application?.email || '';
    const isValid = this.emailRegex.test(email);
    if (!isValid) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidEmail);
    }
  };

  private validatePhone = () => {
    const errors = this.getState().user.rentalApplication.errors;
    this.getState().user.rentalApplication.errors = errors.filter((error) => error !== RentalApplicationErrorCode.InvalidPhone);

    let isValid = false;
    const phoneNumber = this.getState().user.rentalApplication.application?.phone || '';
    const digitsOnly = phoneNumber.replace(/\D+/g, '');
    if (/^(00|\+)/.test(phoneNumber)) {
      isValid = phoneNumber.length > 3;
    } else {
      isValid = digitsOnly.length === 10;
    }
    if (!isValid) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidPhone);
    }
  };

  private validateSSN = () => {
    const errors = this.getState().user.rentalApplication.errors;
    this.getState().user.rentalApplication.errors = errors.filter((error) => error !== RentalApplicationErrorCode.InvalidSSN);

    const isValid = this.ssnRegex.test(this.getState().user.rentalApplication.application?.ssn || '');
    if (!isValid) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidSSN);
    }
  };

  private validateDateOfBirth = () => {
    const errors = this.getState().user.rentalApplication.errors;
    this.getState().user.rentalApplication.errors = errors.filter(
      (error) =>
        error !== RentalApplicationErrorCode.InvalidDateOfBirth &&
        error !== RentalApplicationErrorCode.InvalidDateOfBirthGreater &&
        error !== RentalApplicationErrorCode.InvalidDateOfBirthLesser
    );

    const dateOfBirth = this.getState().user.rentalApplication.application?.dateOfBirth || '';
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    let errorCode;
    if (!this.dateRegex.test(dateOfBirth)) {
      errorCode = RentalApplicationErrorCode.InvalidDateOfBirth;
    } else if (age < 18) {
      errorCode = RentalApplicationErrorCode.InvalidDateOfBirthLesser;
    } else if (age > 125) {
      errorCode = RentalApplicationErrorCode.InvalidDateOfBirthGreater;
    }

    if (errorCode) {
      this.getState().user.rentalApplication.errors.push(errorCode);
    }
  };

  private validateAnnualIncome = () => {
    const errors = this.getState().user.rentalApplication.errors;
    this.getState().user.rentalApplication.errors = errors.filter((error) => error !== RentalApplicationErrorCode.InvalidAnnualIncome);
    const income = this.getState().user.rentalApplication.application?.annualIncome;
    if (!income || income <= 0 || income > MAX_NUMBER) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidAnnualIncome);
    }
  };

  private validateHasAccesptedMagicDoorEULA = () => {
    if (this.getState().user.rentalApplication.application?.hasAcceptedMagicDoorTerms !== true) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidMagicDoorAgreement);
    }
  };

  private validateHasAccesptedTransUnionEULA = () => {
    if (this.getState().user.rentalApplication.application?.hasAcceptedTransUnionTerms !== true) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidTransUnionAgreement);
    }
  };

  private validateEmploymentHistory = () => {
    if (this.getState().user.rentalApplication.application?.employmentHistory) {
      this.getState().user.rentalApplication.employmentHistoryErrors = this.getEmploymentHistoryErrorCodes(
        this.getState().user.rentalApplication?.application?.employmentHistory
      );
    }
  };

  private getEmploymentHistoryErrorCodes = (history?: EmploymentInformation[]): EmploymentHistoryErrorCode[][] => {
    if (!history || history.length === 0) {
      return [];
    }
    return history.map((historyElement: EmploymentInformation) => {
      const errors = [
        this.validateEmployerName(historyElement.name),
        this.validateEmployerPhoneNumber(historyElement.phone),
        this.validatePosition(historyElement.position),
        this.validateSalary(historyElement.salary),
        this.validateStartDate(historyElement.startDate),
        this.validateEndDate(historyElement.endDate),
      ].filter((code): code is EmploymentHistoryErrorCode => code !== undefined);
      return errors;
    });
  };

  private validateEmployerName = (name?: string): EmploymentHistoryErrorCode | undefined => {
    if (!name || !this.nameRegex.test(name)) {
      return EmploymentHistoryErrorCode.InvalidNameError;
    }
    return undefined;
  };

  private validateEmployerPhoneNumber = (phoneNumber?: string): EmploymentHistoryErrorCode | undefined => {
    if (phoneNumber && !this.isValidPhoneNumber(phoneNumber)) {
      return EmploymentHistoryErrorCode.InvalidPhoneError;
    }
    return undefined;
  };

  private validatePosition = (position?: string): EmploymentHistoryErrorCode | undefined => {
    if (!position || position.length === 0) {
      return EmploymentHistoryErrorCode.InvalidPositionError;
    }
    return undefined;
  };

  private validateSalary = (salary?: number): EmploymentHistoryErrorCode | undefined => {
    if (salary && salary < 0 && salary > MAX_NUMBER) {
      return EmploymentHistoryErrorCode.InvalidSalaryError;
    }
    return undefined;
  };

  private validateStartDate = (date?: string): EmploymentHistoryErrorCode | undefined => {
    if (!date || date.length === 0 || !this.dateRegex.test(date)) {
      return EmploymentHistoryErrorCode.InvalidStartDateError;
    }
    return undefined;
  };

  private validateEndDate = (endDate?: string, startDate?: string): EmploymentHistoryErrorCode | undefined => {
    if (!endDate) {
      return undefined;
    }

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    endDate = endDate || '';
    startDate = startDate || '';
    const endDateObj = new Date(endDate);
    const startDateObj = new Date(startDate);

    if (endDate.length > 0 && !dateRegex.test(endDate)) {
      return EmploymentHistoryErrorCode.InvalidEndDateError;
    } else if (endDate.length > 0 && dateRegex.test(endDate) && dateRegex.test(startDate) && startDateObj >= endDateObj) {
      return EmploymentHistoryErrorCode.InvalidEndDateEarlierError;
    }
    return undefined;
  };

  private isValidPhoneNumber = (phoneNumber: string): boolean => {
    let isValid = false;
    const digitsOnly = phoneNumber.replace(/\D+/g, '');
    if (/^(00|\+)/.test(phoneNumber)) {
      isValid = phoneNumber.length > 3;
    } else {
      isValid = digitsOnly.length === 10;
    }
    return isValid;
  };
}
